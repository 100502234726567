// Variables

$blue: #040510;
$lighter-blue: #090a1b;
$white: white;
$border-radius: 1rem;
$border: 1px solid white;
$roboto: 'Roboto';
$montserrat: 'Montserrat';


// Other styles
@import './Menu.scss';
@import './Content.scss';
@import './Login.scss';
@import './Forms.scss';
@import './Buttons.scss';
@import './ReactLibComponents.scss';
@import './Loaders.scss';


// Styling

html, body {
    margin: 0;
    padding: 0;
    background: $blue;
    color: $white;
    font-family: $roboto;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $montserrat;
    font-weight: bold;
}

.container {
    display: grid;
    grid-template-columns: 300px 1fr;
    width: 100vw;
    height: 100vh;
}


.errorpage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {font-size: 4rem; padding: 0; margin: 0; margin-bottom: 1rem;}
    p {font-size: 1.25rem; padding: 0; margin: 0;}
    a {color: white; text-decoration: none;}
}
