form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: $montserrat;

    &.row {
        flex-direction: row;
        align-items: center;
        margin-bottom: 1.5rem;
        .form-group {
            margin-bottom: 0;
            margin-right: 1.5rem;
        }
    }

    .form-group {
        margin-bottom: 1.5rem;

        p {
            padding: 0;
            margin: 0;
            font-size: 1.1rem;
            margin-bottom: 1rem;

            &.smaller {
                margin-left: 1rem;
                margin-bottom: .5rem;
                font-weight: normal;
                font-size: 1rem;
            }
        }

        input, textarea {
            padding: .75rem 1.25rem;
            margin: 0;
            background: $lighter-blue;
            border: none;
            border-radius: .5rem;
            outline: none;
            color: white;
            font-size: 1.1rem;
            font-family: $roboto;
            box-shadow: 0 0 15px 8px rgba(255, 255, 255, .06);
            border: 1px solid #ffffff36;
            margin-bottom: .75rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        select {
            padding: .15rem .25rem;
            margin: 0;
            background: none;
            border: $border;
            border-radius: .2rem;
            offset: 0;
            outline: none;
            color: white;
            font-size: 1rem;
            font-family: $roboto;
            cursor: pointer;

            option {
                color: white;
                background: $lighter-blue;

                &:hover {
                    background: $blue;
                }
            }
        }
    }
}
