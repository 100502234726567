.loading {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-content: center;

    .icon {
        font-size: 4rem;
        animation: loading 1s ease-in-out infinite;
    }
}

.btn-loading {
    background: $blue;
    border: $border;
    padding-left: 1rem;
    padding-right: 1rem;
    &:hover {
        background: $blue;
    }
    .icon {
        animation: loading 1s ease-in-out infinite;
    }
}

@keyframes loading {
    from {transform: rotate(0deg);}
    to, 80% {transform: rotate(360deg);}
}
