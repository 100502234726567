// Toastify

.Toastify__toast-container {
    width: 20% !important;
    bottom: 1rem !important;
    right: 1rem !important;
    top: auto !important;

    @media only screen and (max-width: 500px) {
        width: calc(100% - 2rem) !important;
        bottom: 1rem !important;
        right: 1rem !important;
        left: auto !important;
    }
}

