.nav {
    position: relative;
    background: $lighter-blue;
    height: 100vh;
    margin: 0;
    padding: 0;

    .heading {
        margin: 0;
        padding: 0;
        text-align: center;
        padding-top: 2rem;

        .icon {margin-right: .5rem; font-size: 1.75rem;}
    }

    &__menu {
        margin: 0;
        padding: 1rem;
        width: auto;
        list-style: none;
    }

    &__link {
        padding: 0;
        width: 100%;
        margin: 0;
        margin-bottom: .5rem;

        &:first-child {
            margin-top: .75rem;
        }

        a {
            display: block;
            padding: .75rem 1rem;
            width: calc(100% - 2rem);
            border-radius: $border-radius;
            color: #bbbbbb;
            font-size: 1.1rem;
            font-family: $montserrat;
            font-weight: 500;
            text-decoration: none;
            transition: .3s;

            &:hover, &.active {color: white; background: #ffffff09;}

            .icon {margin-right: .75rem; font-size: 1.2rem;}
        }
    }

    .user {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: .5rem 1.5rem;
        background: #ffffff03;
        text-decoration: none;
        color: white;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .link-button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        &__avatar img {
            width: 2.5rem;
            height: 2.5rem;
        }

        &__info .name {
            font-size: 1.1rem;
            font-weight: 500;
            margin-left: 1rem;
        }

        .logout {
            position: absolute;
            right: 1rem;
            font-size: 1.25rem;
            padding: .75rem;
            border-radius: $border-radius;
            background: $lighter-blue;
            transition: .3s;
            cursor: pointer;
            z-index: 10;

            &:hover {background: $blue;}
        }

        &.active {background: #ffffff0a;}
    }
}
