.btn {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    offset: 0;
    outline: none;
    color: white;
    cursor: pointer;
    transition: .3s;
}

.btn-primary {
    padding: .75rem 1.75rem;
    background: $lighter-blue;
    border-radius: .5rem;
    border: $border;
    font-size: 1rem;
    font-family: $montserrat;

    &:hover {background: #0e102d;}
}

.btn-submit {
    padding: .75rem 1.75rem;
    background: #08a508;
    border-radius: .5rem;
    font-size: 1.1rem;
    font-family: $montserrat;

    &:hover {background: #0dbc0d;}
}

.btn-danger {
    padding: .75rem 1rem;
    background: #9c0000;
    border-radius: .5rem;
    font-size: 1.1rem;
    font-family: $montserrat;

    &:hover {background: #c10000;}
}
