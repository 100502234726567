.content {
    height: calc(100vh - 4rem);
    padding: 2rem;
    overflow-y: auto;

    .home-heading {
        font-size: 2.25rem;
        margin: 0;
        padding: 0;
    }
}

.wrapper {
    margin: 1rem;

    &__heading {
        margin: 0;
        border-bottom: 1px solid white;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        width: 100%;

        a {
            color: white;
            margin-right: 1rem;
        }
    }

    &__list {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li a {
            display: block;
            color: white;
            font-size: 1.25rem;
            font-weight: 500;
            text-decoration: none;
            background: #ffffff05;
            padding: .75rem 1.5rem;
            border-radius: .5rem;
            margin-bottom: 1.25rem;
            transition: .3s;
            max-width: 30%;
            box-shadow: 0 0 15px 5px rgba(255, 255, 255, .05);
            border: 1px solid #ffffff21;

            &:hover {background: #ffffff0a;}
        }

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            color: white;
            background: #ffffff05;
            padding: .5rem .75rem;
            border-radius: .5rem;
            margin-bottom: 1rem;
            transition: .3s;
            max-width: 30%;
            min-width: 400px;
            box-shadow: 0 0 15px 5px rgba(255, 255, 255, .05);
            border: 1px solid #ffffff21;

            &:hover {background: #ffffff0a;}

            p {font-size: 1.1rem; margin: 0; margin-left: .5rem;}
        }

        form {margin-top: 3rem;}
    }

    &__form {
        width: 25vw;

        .form-group, input, textarea {width: 100%;}
        textarea {height: auto; min-height: 150px;}
        .btn {margin: auto;}
    }

    &__gallery {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;

        form {
            .form-group input {
                font-size: 1rem;
                padding: .25rem;
            }

            .btn-submit {
                font-size: 1rem;
                padding: .5rem .75rem;
            }
        }

        .gallery {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;

            .image-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0 1rem 1rem 0;

                img {
                    width: 250px;
                    height: 250px;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: .5rem;
                }

                .image-options {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    width: 90%;
                    margin-top: .5rem;

                    select {
                        padding: .15rem .25rem;
                        margin: 0;
                        background: none;
                        border: $border;
                        border-radius: .2rem;
                        offset: 0;
                        outline: none;
                        color: white;
                        font-size: 1rem;
                        font-family: $roboto;
                        cursor: pointer;

                        option {
                            color: white;
                            background: $lighter-blue;

                            &:hover {
                                background: $blue;
                            }
                        }
                    }

                    .btn-danger {
                        padding: .25rem .5rem;
                    }
                }
            }
        }
    }

    &__user {
        h3 {font-size: 1.25rem;}

        .avatar-images {
            margin-top: 2rem;

            .images {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;

                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: .2rem;
                    border-radius: 100px;
                    opacity: .4;
                }

                .active {opacity: 1;}
                img {width: 100px; height: 100px;}
            }

            .btn-submit {
                margin-top: 1rem;
                font-size: 1.1rem;
                padding: .5rem 1rem;
            }
        }

        form {
            margin-top: 2rem;

            .form-group {
                width: 25%;
                p {font-size: 1rem;}
                input {width: calc(100% - 2.5rem - 2px);}

                .green-border {border: 1px solid #08a508;}
                .red-border {border: 1px solid #c10000;}
            }

            .btn-submit {
                font-size: 1.1rem;
                padding: .5rem 1rem;
            }
        }
    }
}
